import * as React from "react";
import LeftArrow from "../../SVGIcons/LeftArrow/LeftArrow";

import styles from "./BackArrowButton.module.scss";

type BackArrowButtonProps = {
  link: string;
  linkCopy: string;
  className?: string;
  color?: string;
};

const BackArrowButton: React.FC<BackArrowButtonProps> = ({
  link,
  linkCopy,
  className = "",
  color = "",
}) => (
  <a
    className={`${styles.arrowBackContainer} ${className} ${color}`}
    href={link}
    data-testid="a:BackArrowButton"
  >
    <span className={styles.arrowIcon}>
      <LeftArrow cls={`${styles.icon} ${color}`} />
    </span>
    {linkCopy}
  </a>
);

export default BackArrowButton;

import * as React from "react";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import BackArrowButton from "../../components/Buttons/BackArrowButton/BackArrowButton";

import { Image, ImageMediaItemUrl } from "@types";
import getImageSrcData from "../../utils/getImageSrcData";

import styles from "./WhatNewsContainer.module.scss";

type WnContainerProps = {
  title: string;
  whatsNewC: {
    whatsNewImage: Image & ImageMediaItemUrl;
  };
};

export const WhatNewsContainer: React.FC<WnContainerProps> = ({
  title,
  whatsNewC: { whatsNewImage },
}) => {
  const image = getImageSrcData(whatsNewImage);
  const wnImage =
    typeof image === "object" ? (
      <GatsbyImage
        image={image as IGatsbyImageData}
        alt={whatsNewImage?.altText || ""}
        className={styles.wnImage}
      />
    ) : (
      <img
        src={image as string}
        alt={whatsNewImage?.altText || "What's New Image"}
        className={styles.wnImage}
      />
    );

  return (
    <section className="pad-top--large-sans-header full-width narrow">
      <div className={`pad-bottom--48 ${styles.divContainer}`}>
        <BackArrowButton
          link="/features/updates"
          linkCopy="What's New"
          className={`h4 primary--blue-text ${styles.arrowIcon}`}
        />
        <span className="hr hr--16" />
        <h1 className="h1">{title}</h1>
      </div>

      {wnImage}

      <span className="hr hr--48"></span>
      <div className="styled-content"></div>
    </section>
  );
};

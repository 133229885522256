import * as React from "react";
import { graphql, PageProps } from "gatsby";
import MainLayout from "../../layouts/main";

import { WhatNewsContainer } from "../../containers/WhatNewsContainer";
import { Image, ImageMediaItemUrl } from "src/assets/Types";

type IWnContainer = {
  wpWhatsNewSingle: {
    title: string;
    whatsNewC: {
      whatsNewImage: Image & ImageMediaItemUrl;
    };
  };
};

const WnPage: React.FC<PageProps<IWnContainer>> = ({
  data: {
    wpWhatsNewSingle: { title, whatsNewC },
  },
}) => {
  return (
    <MainLayout hasFooterLargeCta={true}>
      <WhatNewsContainer title={title} whatsNewC={whatsNewC} />
    </MainLayout>
  );
};

export default WnPage;

export const WHATS_NEW_TEMPLATE_INFO = graphql`
  query WhatsNewTemplateQuery($id: String!) {
    wpWhatsNewSingle(id: { eq: $id }) {
      title
      whatsNewC {
        whatsNewImage {
          altText
          mediaItemUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1040
                placeholder: BLURRED
                formats: [WEBP, JPG, AUTO]
              )
            }
          }
        }
      }
    }
  }
`;
